import React from "react";

import { NetworkErrorMessage } from "./NetworkErrorMessage";

export function ConnectWallet({ connectWallet, networkError, dismiss }) {
  return (
    <div className="container">
      {/* Metamask network should be set to Localhost:8545. */}
      {networkError && (
        <NetworkErrorMessage
          message={networkError}
          dismiss={dismiss}
        />
      )}
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="radio"
          name="SignUp_PopupSelect"
          id="SignUp_PopupSelect1"
          value="meta"
          onClick={connectWallet}
          defaultChecked
        />

        <label className="form-check-label" htmlFor="SignUp_PopupSelect1">
          <div className="row justify-content-center align-items-center">
            <div className="col-3 text-center">
              <img alt="Meta" src={require('../assets/images/metamask_dropdown_logo.png')} />
            </div>
            <div className="col-9">
              <h5 className="m-0"> Connect with Metamask </h5>
            </div>
          </div>
        </label>
      </div>
      <div className="form-check mb-3">

        <input
          className="form-check-input"
          type="radio"
          value="coinbase"
          name="SignUp_PopupSelect"
          id="SignUp_PopupSelect2"
          onClick={connectWallet}
        />

        <label className="form-check-label" htmlFor="SignUp_PopupSelect2">
          <div className="row justify-content-center align-items-center">
            <div className="col-3 text-center">
              <img alt="Coinbase" src={require('../assets/images/coinbasewallet_dropdown_logo.png')} />
            </div>
            <div className="col-9">
              <h5 className="m-0"> Coinbase Wallet </h5>
            </div>
          </div>
        </label>
      </div>
      {/* <button
        className="btn btn-warning"
        type="button"
        onClick={connectWallet}
      >
        Connect Wallet
      </button> */}

    </div >
  );
}
