import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from "wowjs";


export default class Footer extends Component {

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init()
  }
  render() {
    return (
      <div>
        <footer className="main-footer">
          {/* <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div className="footer-logo mb-3">
                    <img className="img-fluid" src={require("../assets/images/nms-logo.png")} alt="Logo" />
                  </div>
                  <p> The world’s first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.</p>

                </div>
                <div className="col-12 col-lg-7 mt-5">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="widget-footer">
                        <h5> New Money Cryptocurrency
                        </h5>
                        <ul>
                          <li> <Link to='/'> Home</Link></li>
                          <li> <Link to='/About'> About</Link></li>
                          <li> <Link to='/FAQ'> FAQ </Link></li>
                          <li> <a href="mailto:jasonmbulluck@gmail.com?subject=New Money Cryptocurrency Site"> Contact Us</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 d-flex justify-content-center">
                      <div className="widget-footer">
                        <h5> Resources</h5>
                        <ul>
                          <li> <Link to='/'> Docs</Link></li>
                          <li> <Link to='/'> Newsletter</Link></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 d-flex justify-content-end">
                      <div className="widget-footer">
                        <h5> Company</h5>
                        <ul>
                          <li> <Link to='/About'> About</Link></li>
                        </ul>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div> */}

          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className='col-md-2 col-12'>
                  <div className="footer-logo">
                    <img className="img-fluid" src={require("../assets/images/nms-logo.png")} alt="Logo" />
                  </div>
                </div>
                <div className='col-md-10 col-12'>
                  <div className="links">
                    <ul>
                      <li> <Link to='/'> Home</Link></li>
                      <li> <Link to='/About'> About</Link></li>
                      <li> <Link to='/FAQ'> FAQ </Link></li>
                      <li> <a href="mailto:jasonmbulluck@gmail.com?subject=New Money Cryptocurrency Site"> Contact Us</a></li>
                      <li> <Link to='/PrivacyPolicy'> Privacy Policy </Link></li>
                      {/* <li className="white"> | </li> */}
                      <li> <Link to='/TermsOfService'> Terms of Service</Link></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
