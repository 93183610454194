import React, { Component } from "react";
import AudioAnalyser from "react-audio-analyser";
import API from '../api.js'
import { withUrlParams } from '../components/UrlParams'
import { Loading } from '../components/Loading.js';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";



class AddAudio extends Component {

  constructor(props) {

    super(props);

    this.contract = this.props.contract;
    this.selectedAddress = this.props.selectedAddress;
    this.api = new API(this.props.contract);


    this.state = {
      status: "",
      loading: true,
      redirect: false,
      notavail: false,
      tokenIds: '',
      tokenmessage: '',
      audfile: '',
      upbtn: false,
      pagedata: [],
    };


  }

  controlAudio(status) {
    this.setState({
      status,
    });
  }

  changeScheme(e) {
    this.setState({
      audioType: e.target.value,
    });
  }

  async getPageData() {
    const response = await fetch(`${process.env.REACT_APP_HOST_URL}audiopage/1`)
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }

  async componentDidMount() {

    let nfts = await this.api.getNft(this.props.params.id);
    this.chkTokenAvailability(this.props.params.id);
    this.setState({
      audioType: "audio/mp3"
    });
    let mypage = await this.getPageData();
    this.setState({ pagedata: mypage.data.data, loading: false });
  }

  async chkTokenAvailability(nftc) {
    // console.log(nftc)
    const response = await this.props.contract.isBoughtOffPrivateExchange(
      nftc,
    )
    if (response) {
      this.setState({ notavail: false, tokenmessage: 'This token is not available for sale, so you cannot upload audio' });
    } else {
      const tokenId = await this.api.getTokenIDsOfOwner(this.props.selectedAddress);
      if (tokenId) {
        // console.log("HR");
        // console.log(pft.id);
        if (tokenId.has(this.props.params.id)) {
          this.setState({ notavail: true });
        } else {
          this.setState({ tokenmessage: 'You are not the owner of this token. Please own the token to upload audio' });
        }
      }
    }
    this.setState({ loading: false })

    return response;
  }

  async onSaveAudio(formdata) {

    this.setState({ loading: true });

    // const uploadaud = await fetch("https://newmoneyart.com/tokensale", {
    //   method: "post",
    //   body: formdata,
    // })

    const uploadaud = await fetch(process.env.REACT_APP_HOST_URL + "translate-audio", {
      method: "post",
      body: formdata,
    })
    // console.log(await uploadaud.json());
    // if (!uploadaud.ok) {
    //   const message = `An error has occured: ${uploadaud.status}`;
    //   this.setState({ loading: false });
    //   this.showAlert("Message", message, 'error', 'OK', '');
    //   alert(message);
    // }
    let mdata = await uploadaud.json();
    console.log(mdata.data);

    if (mdata.data.status) {
      this.showAlert("Message", mdata.data.Message, 'warning', 'OK', 'redirect');
    } else {
      this.showAlert("Message", mdata.data.Message, 'error', 'OK', 'redirect');
    }

    // if (mdata && mdata.next_version) {

    //   if (mdata.next_version.processing_state === "processing") {

    //     this.showAlert("Message", "Your audio is currently under process. Please wait if processed it will shown in your NFT Page", 'warning', 'OK', 'redirect');

    //   } else if (mdata.next_version.processing_state === "done") {

    //     this.showAlert("Message", "Audio Updated Successfully", 'warning', 'OK', 'redirect');

    //     window.location.href = '/NFTDetails/' + this.props.params.id;

    //   } else if (mdata.next_version.processing_state === "failed") {

    //     this.showAlert("Message", "Audio Processing Failed. Please try later", 'error', 'OK', '');
    //   }
    // }


    this.setState({ loading: false });

  }

  onChangeHandler = async (event) => {


    this.setState({ loading: true });

    const formData = new FormData(); // preparing to send to the server

    formData.append("audioFile", event.target.files[0]);
    formData.append("tokid", this.props.params.id);
    formData.append("userid", this.props.selectedAddress);

    this.onSaveAudio(formData);
  };

  showAlert(mtitle, message, type, btntext, poption) {
    Swal.fire({ title: mtitle, text: message, icon: type, confirmButtonText: btntext, timer: 2500 }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        if (poption == "reload") {
          window.location.reload();
        } else if (poption == "redirect") {
          window.location.href = '/NFTDetails/' + this.props.params.id;
        }
      } else {
        if (poption == "reload") {
          window.location.reload();
        } else if (poption == "redirect") {
          window.location.href = '/NFTDetails/' + this.props.params.id;
        }
      }
    })
  }

  async uploadRecordAudio() {
    this.setState({upbtn:false});
    const formData = new FormData(); // preparing to send to the server

    formData.append("audioFile", this.state.audfile); // preparing to send to the server
    formData.append("tokid", this.props.params.id);
    formData.append("userid", this.props.selectedAddress);
    this.onSaveAudio(formData);
    // console.log(formData);
  }

  render() {


    const { status, audioSrc, audioType } = this.state;
    const { isLoading, isRecording } = this.state;

    const mpagedata = this.state.pagedata;

    const audioProps = {
      audioType,
      // audioOptions: {sampleRate: 30000}, // 设置输出音频采样率
      status,
      audioSrc,
      backgroundColor: '#fff',
      strokeColor: '#000',
      timeslice: 1000, // timeslice（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
      startCallback: (e) => {
        console.log("succ start", e);
        this.setState({upbtn:false});
      },
      pauseCallback: (e) => {
        console.log("succ pause", e);
        this.setState({upbtn:false});
      },
      stopCallback: (e) => {
        this.setState({
          audioSrc: window.URL.createObjectURL(e),
        });
        // const audioBlob = async () =>
        // await fetch(window.URL.crea teObjectURL(e)).then((r) => r.blob());
        const audioFile = new File([e], "snippet.mp3", {
          type: "audio/mp3",
        });

        this.setState({ audfile: audioFile });

        this.setState({upbtn:true});
        // console.log("f", audioFile)

        //this.onSaveAudio(formData);
        // console.log("succ stop", e);
      },

      onRecordCallback: (e) => {
        console.log("recording", e);
        this.setState({upbtn:false});
      },
      errorCallback: (err) => {
        this.setState({upbtn:false});
        console.log("error", err);
      },
    };



    return (
      <div>


        {this.state.loading ? <Loading /> : ''}
        {this.state.notavail ?
          <main className="main-site-wrap">
            <section className="related-page-wrap py-50">
              <div className="container">

                <div className="adudio-record-box mb-5 py-5 wow animate__fadeInUp text-center">
                  <h4 className="mb-4">
                    {" "}
                    <span> {mpagedata.record_title} </span>{" "}
                  </h4>
                  <p>
                    {mpagedata.record_descp}
                  </p>

                  <div className="adudio-record-btnbox pt-4">
                    <AudioAnalyser {...audioProps}>
                      <div className="btn-box">
                        <button
                          disabled={isLoading}
                          onClick={() => this.controlAudio("recording")}
                          className="btn brand-dark-btn submit-btn"
                          style={{ margin: 2 }}
                        >
                          <img alt="Record"
                            src={require("../assets/images/btn_record_ic.png")}
                          />
                          {isRecording ? "Stop" : "Record Audio"}
                        </button>

                        <button
                          onClick={() => this.controlAudio("inactive")}
                          className="btn brand-dark-btn submit-btn"
                        >
                          <img alt="Stop"
                            src={require("../assets/images/btn_record_ic.png")}
                          />
                          Stop
                        </button>
                      </div>
                    </AudioAnalyser>
                    {this.state.upbtn && <button
                      onClick={() => this.uploadRecordAudio()}
                      className="btn brand-dark-btn submit-btn"
                    >
                      Upload
                    </button>}
                  </div>
                </div>
              </div>
            </section>
            <div className="section-heading wow animate__fadeInUp pt-5 pb-4">
              <div className="container">
                <h2 className="title"> {mpagedata.upload_title} </h2>
                <p>{mpagedata.upload_desc}</p>

                <div className="adudio-upload-form mt-3 mb-5 wow animate__fadeInUp">
                  <form action="">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        <strong> Drag and drop files, or Browse </strong>
                        <span className="brand-btn"> Upload </span>
                      </button>
                      <input
                        type="file"
                        name="audioFile"
                        onChange={this.onChangeHandler}
                      />
                    </div>
                    <div className="submit-btn-box pt-5"></div>
                  </form>
                </div>
              </div>
            </div>
          </main>
          : <div><main className="main-site-wrap">
            <section className="related-page-wrap py-50">
              <div className="container text-center">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <h2 className="text-center mt-4">{this.state.tokenmessage}</h2>
                    <Link to={'/Marketplace'} className='btn brand-btn mt-4'>Go to Marketplace</Link>
                  </div>
                </div>
              </div>
            </section>
          </main>
          </div>
        }
      </div>
    );
  }
}

export default withUrlParams(AddAudio);