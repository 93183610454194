import React, { Component } from 'react';
import API from '../api.js'
import NftSingle from './NftSingle'
import { Loading } from "./Loading";
import ModalForm from '../components/ModalForm.js';
import { withUrlParams } from '../components/UrlParams'

class NFTDetailsNew extends Component {
  constructor(props) {
    super(props);

    // console.log(this.props.selectedAddress);

    this.contract = this.props.contract;
    this.selectedAddress = this.props.selectedAddress;
    this.api = new API(this.props.contract);

    // console.log(this.props.contract);

    this.state = {
      nfts: [],
      fid: [],
      fpid: [],
      audids: [],
      audapids: [],
      loading: true,
      tokenIds: '',
      buttonstxt:''
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = () => this.setState({ isOpen: false });

  }


  async openModal(event) {

    this.setState({ isOpen: true });

    await this.setState({ tokenid: event.target.getAttribute('data-id') }, () => {
      console.log(this.state.tokenid);
    });
  }

  async componentDidMount() {

    // console.log(this.props.params.id);
    let nfts = await this.api.getNft(this.props.params.id);
    let btndata = await this.api.getButtons();
    console.log("sdf", btndata)
    let pricenfts = await this.api.getpriceNfts();
    let audiofts = await this.api.getAudioRequests();
    let audioftsapp = await this.api.getAudioRequestsApproved(this.props.params.id);
    let tokenId = '';
    let audioids = null;
    let audioappids = '';

    console.log("Audios", audioftsapp);
    if (this.props.selectedAddress) {
      tokenId = await this.api.getTokenIDsOfOwner(this.props.selectedAddress);
    }
    let followingIds = '';
    let followingPrice = '';

    if (pricenfts.data && pricenfts.data.status) {
      followingIds = pricenfts.data.tokenlist.map(group => parseInt(group.tokenid));
      followingPrice = pricenfts.data.tokenlist.map(group => group.price);
    }

    if (audiofts.data && audiofts.data.status) {
      audioids = audiofts.data.data.map(group => parseInt(group.token_id));
      // console.log("audiocheck", audioids);     
    }

    if (audioftsapp.data && audioftsapp.data.status) {
      audioappids = audioftsapp.data.data.map(group => parseInt(group.token_id));
      // console.log("audiocheck", audioids);     
    }

    this.setState({ nfts: nfts, loading: false, fid: followingIds, fpid: followingPrice, approveaud: audioftsapp, audids: audioids, audapids: audioappids, tokenIds: tokenId, buttonstxt: btndata.data.data });
  }

  createIpfsLink(ipfsHash) {
    return ipfsHash.replace("ipfs://", "https://ipfs.io/ipfs/");
  }

  simulateNftPurchase(nft, event) {

    console.log(nft)
    console.log("Simulating NFT purchase...");

  }

  render() {
    if (!this.state.loading) {

      const pft = this.state.nfts;
      const btn = this.state.buttonstxt;

      let buybtns = false;
      let nftm = pft;
      let snippet = '';
      let audiousers = '';

      if (pft.next_version) {
        snippet = Object.entries(pft.next_version.snippets);
      } else {
        snippet = Object.entries(pft.snippets);
      }
      // console.log(pft);
      console.log("next", nftm.next_version);
      let chkprivate = nftm.private;
      let isForSale = nftm.next_version !== null;
      console.log("Sale", isForSale);

      nftm = isForSale ? nftm.next_version : nftm;

      if (nftm.processing_state == "failed") {
        nftm.animation_url = nftm.snippets.audio_snippet_1;
      } else {
        nftm.animation_url = nftm.animation_url;
      }
      let mprice = '';
      let claim = true;
      let owner = false;
      let aud = false;
      let audexist = false;
      let tokprivate = false;
      if (this.state.fid.includes(nftm.id)) {
        mprice = this.state.fpid[this.state.fid.indexOf(nftm.id)] + ' Eth';
      }

      if (this.state.tokenIds) {
        // console.log("HR");
        // console.log(pft.id);
        if (this.state.tokenIds.has(pft.id.toString())) {
          claim = false;
          owner = true;
        }

        if (!chkprivate) {
          tokprivate = true;
        }

      } else if (!this.props.selectedAddress) {
        claim = false;
        owner = false;
      }
      if (this.state.audapids.includes(pft.id)) {
        audexist = true;
      }
      if (this.state.audids.includes(pft.id)) {
        aud = true;
      }
      // console.log("Private", pft.id.toString());
      // console.log("TOkenids", this.state.tokenIds);
      // console.log("Owner", owner);
      // console.log("Claim", claim);
      if (this.state.approveaud && this.state.approveaud.data) {
        audiousers = this.state.approveaud.data.data.map((audrequest, i) => {
          return (
            <div className='userList'>
              <h5 style={{ marginTop: '15px', marginBottom:'10px'}}>Users Token Held</h5>
              <ul style={{ }}>
                <li style={{borderBottom:'solid 1px #222', background:'#111', padding:'10px'}}>{audrequest.user_id}</li>
              </ul>
            </div>
          )
        })
      }


      return (
        <>

          <div className="container">
            <div className="row justify-content-md-center position-relative" style={{ minHeight: '200px' }}>
              <NftSingle
                isForSale={isForSale}
                contract={this.props.contract}
                selectedAddress={this.props.selectedAddress}
                transferbtn={claim}
                owned={owner}
                buybtn={chkprivate}
                modalbtn={this.openModal}
                price={mprice}
                nft={nftm}
                requestbtn={owner}
                audiostatus={aud}
                privatetok={tokprivate}
                audioapp={audexist}
                btntxt={btn}
              ></NftSingle>
            </div>

            {/* {audiousers} */}


            <ModalForm
              closeModal={this.closeModal}
              isOpen={this.state.isOpen}
              tokens={this.state.tokenid}
              loading={this.state.loading}
              userid={this.props.selectedAddress}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </>
      );
    } else {
      return <Loading />
    }
  }
}
export default withUrlParams(NFTDetailsNew);