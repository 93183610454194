import React, { Component } from 'react';
import { Link } from "react-router-dom";
import API from '../api.js';
import { Loading } from './Loading.js';
import Swal from 'sweetalert2';
import { ethers } from "ethers";

export default class Nft extends Component {
  constructor(props) {
    super(props);

    this.contract = this.props.contract;
    // console.log("Buttons", this.props.btntxt);
    this.selectedAddress = this.props.selectedAddress;
    this.api = new API(this.props.contract);

    this.putTokenForSale = this.putTokenForSale.bind(this);
    this.transferNft = this.transferNft.bind(this);
    this.buyNft = this.buyNft.bind(this);

    this.state = {
      nft: props.nft,
      recentlyUpdatedMetadataUrl: null,
      audioSnippetUrl: null,
      isForSale: this.props.isForSale,
      recentlyUpdated: false,
      loading: false,
      recentlyTransferred: false,
      canSimulateTransfer: false
    };
    // console.log(this.props.btnstate);
  }

  formatLink(ipfsHash) {
    return ipfsHash.replace("ipfs://", "https://ipfs.io/ipfs/");
  }

  async putTokenForSale(event) {
    event.preventDefault();

    const url = event.target.value.trim();

    // validate url
    if (url.indexOf("https://") !== 0) {
      alert('Please paste a valid URL here');
      return;
    }

    this.setState({ loading: true });
    console.log("Simulating putting NFT for sale...");

    // This API call is made here for demo purposes
    // In production, the FE should make an API call to the backend
    // to ensure that the user is the owner of the NFT, and to protect
    // against exposing the API key.
    let response = await this.api.putTokenForSale(this.state.nft.id, event.target.value);
    let data = await response.json()

    this.setState({
      // This is nice, but a broken IPFS link will break the NFT and this hides the fact that it's broken.
      nft: data.next_version,
      recentlyUpdated: true,
      loading: true,
      canSimulateTransfer: false
    });

    // The API returns the updated metadata immediately, but the audio processing
    // happens in the background. Retry getting the NFT until the processing state
    // is updated to "done".
    // The application should also cater for the case where the processing state
    // is "failure", which can happen.
    let interval = setInterval(async () => {

      let data = await this.api.getNft(this.state.nft.id);

      if (data.next_version.processing_state === "done") {
        clearInterval(interval);
        this.setState({
          nft: data.next_version,
          recentlyUpdated: true,
          loading: false,
          canSimulateTransfer: true
        });
      }

      if (data.next_version.processing_state === "error") {
        clearInterval(interval);
        this.setState({
          nft: data.next_version,
          recentlyUpdated: true,
          loading: false,
          canSimulateTransfer: false
        });
      }

      if (data.next_version.processing_state === "processing") {
        this.setState({
          nft: data.next_version,
          recentlyUpdated: true,
          loading: true,
          canSimulateTransfer: false
        });
      }
    }, 2000);
  }

  async transferNft(event) {

    event.preventDefault();

    this.setState({ loading: true })

    console.log("Generating signature...");

    // This API call is made here for demo purposes
    // In production, the FE should perform this procedure on the backend
    // to ensure that the NFT is actually for sale, and has gone through
    // the moderation flow to approve it's sale.

    // First we're getting the owner of the token since it's
    // required to generate the signature.
    // this.myapi = new API(this.props.contract);
    // console.log("My");
    // console.log(this.props.contract.ownerOf(this.state.nft.id));

    const owner = await this.api.getOwnerOf(this.state.nft.id);

    // Then we're generating the signature.
    // The API helper is generating a nonce for us, but in production
    // the nonce should be incrementally generated and saved in the database.
    let { signature, nonce } = await this.api.fetchMintingSignature(owner, this.selectedAddress, this.state.nft.id);

    console.log({ signature, nonce });

    console.log("Transferring NFT...");

    // This triggers the blockchain interaction to transfer the token.
    // This piece of code must live on the frontend: it's ok to expose
    // the signature and nonce to the frontend.
    try {
      const response = await this.contract.transfer(
        this.selectedAddress,
        this.state.nft.id,
        nonce,
        signature
      )
      console.log(response);
      console.log(response.hash);
      this.setState({ loading: false });
      this.showAlert("Message", "Transaction Successful", 'success', 'OK', 'reload');
      // alert("Transaction Successfull");
      // window.location.reload();
    } catch (error) {
      // console.log(error.code);
      if (error.code === 4001) {
        this.showAlert("Message", "User Rejected Request", 'warning', 'OK', '');
        // alert('User Rejected Request');
      }
      else if (error.code === 'INSUFFICIENT_FUNDS') {
        this.showAlert("Message", "Insufficent Balance. Please add some balance", 'warning', 'OK', '');
        // alert('Insufficient Balance');
      }
      this.setState({ loading: false });
    }



    let interval = setInterval(async () => {

      let data = await this.api.getNft(this.state.nft.id);

      if (data.next_version == null) {
        clearInterval(interval);
        this.setState({
          nft: data,
          recentlyTransferred: true,
          recentlyUpdated: false,
          canSimulateTransfer: false,
          loading: false,
        });
      }
    }, 2000);



  }

  async buyNft(event) {
    event.preventDefault();

    this.setState({ loading: true })

    // let owner = await this.api.getOwnerOf(this.state.nft.id);
    // console.log("Owner: " + owner);

    // Let's set a price for the demo.
    // In real life, this price will need to be approved by Jason
    let amount = 0;
    // console.log(this.state.nft.id)
    let chkprice = await this.api.getpriceNft(this.state.nft.id);

    if (chkprice.data) {

      if (chkprice.data.status && chkprice.data.tokenlist) {
        // console.log(ethers.utils.parseUnits(chkprice.data.tokenlist[0].price, "ether"));
        amount = parseInt(chkprice.data.tokenlist[0].price * 10 ** 18).toString();
      }
    }

    // amount = 10000000000;
    console.log(amount);

    console.log("user: " + this.selectedAddress);

    const response1 = await this.contract.isBoughtOffPrivateExchange(
      this.state.nft.id,
    );

    if (!response1) {

      let { signature } = await this.api.fetchBuyingSignature(this.selectedAddress, this.state.nft.id, amount);

      console.log("Buying NFT...");



      // This triggers the blockchain interaction to transfer the token.
      // This piece of code must live on the frontend: it's ok to expose
      // the signature and nonce to the frontend.
      try {

        const response = await this.contract.buyOffPrivateExchange(
          this.state.nft.id,
          signature, {
          value: amount
        });
        console.log(response);
        this.setState({ loading: false });
        this.showAlert("Message", "Transaction Successful", 'success', 'OK', 'reload');
        // alert("Transaction Successful");
        // window.location.reload();
      }
      catch (error) {
        console.log(error.code);
        if (error.code === 4001) {
          this.showAlert("Message", "User Rejected Request", 'warning', 'OK', '');
          // alert('User Rejected Request');
        } else if (error.code === 'INSUFFICIENT_FUNDS') {
          this.showAlert("Message", "Insufficent Balance. Please add some balance", 'warning', 'OK', '');
          // alert('Insufficient Balance');
        }
        this.setState({ loading: false });
      }
    } else {
      this.showAlert("Message", "Not available to Buy", 'error', 'OK', '');
      // alert("Not available to Buy");
    }
    this.setState({ loading: false })
  }

  async getTokensOfOwner(selectedAddress) {
    if (selectedAddress) {
      let tokenIds = await this.api.getTokenIDsOfOwner(selectedAddress);
      console.log(tokenIds);
      let nfts = await this.getNfts();
      // filter tokens by id
      return nfts.filter(nft => tokenIds.has(nft.id.toString()));
    }
  }

  showAlert(mtitle, message, type, btntext, poption) {
    Swal.fire({ title: mtitle, text: message, icon: type, confirmButtonText: btntext, timer: 2500 }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        if (poption == "reload") {
          window.location.reload();
        }
      } else {
        if (poption == "reload") {
          window.location.reload();
        }
      }
    })
  }


  render() {
    let status = 'Pending';
    let bgcolor = 'info';
    // if (this.props.selectedAddress && this.props.contract) {
      if (!this.props.privatetok) {
        if (!this.props.transferbtn && this.props.audiostatus) {
          status = 'Processing';
          bgcolor = 'warning';
        } else if (this.props.transferbtn && this.props.audiostatus) {
          status = 'Processing';
          bgcolor = 'warning';
        } else if (!this.props.transferbtn && !this.props.audiostatus && this.props.owned) {
          status = 'Owned'
          bgcolor = 'primary';
        } else if (this.props.transferbtn && !this.props.audioapp) {
          status = 'Available'
          bgcolor = 'success';
        } else if (this.props.audioapp) {
          status = 'Held'
          bgcolor = 'info';
        } else {
          status = 'Available'
          bgcolor = 'success';
        }
      } else {
        status = 'Not Available'
        bgcolor = 'danger';
      }
    // } else {
    //   status = ''
    // }
    // console.log("Audioapp", this.props.transferbtn)

    return (

      <div className="col-md-3" >
        {this.state.loading ? <Loading /> : ''}

        <div className="card mb-4 nftlist nftlst"
          style={{ border: "none" }}
        >
          <Link to={`/NFTDetails/${this.state.nft.id}`}> <img src={this.formatLink(this.state.nft.image)} className="card-img-top rounded" alt="..." /></Link>
          <div className="card-body">

            <h5>{this.state.nft.name}</h5>

            {this.state.nft.processing_state === "done" || this.state.nft.processing_state === undefined ? <audio src={this.formatLink(this.state.nft.animation_url)} controls style={{ width: '100%' }} /> : ''}

            {this.state.nft.processing_state === "failed" ? <audio style={{ width: '100%' }} src={this.formatLink(this.state.nft.animation_url)} controls /> : ''}

            {this.state.nft.processing_state === "processing" ? <p>Processing Audio. Please wait few minutes..</p> : ''}

            {/* {this.state.nft.processing_state ? 'Processing state: ' + this.state.nft.processing_state : ''} */}
            <div className='text-center'>

              {/* {!this.props.selectedAddress && this.props.transferbtn  && (this.state.recentlyUpdated || this.state.nft.processing_id !== undefined) ? <button className="btn btn-sm btn-secondary mt-2" disabled="disabled">Transfer on PX</button> : <button className="btn btn-sm btn-primary mt-2" onClick={this.transferNft} disabled={this.canSimulateTransfer}>Transfer on PX</button>} */}

              {/* Main--- {this.props.selectedAddress && this.props.transferbtn ? <button className="btn btn-sm btn-primary mt-2" onClick={this.transferNft} disabled={this.canSimulateTransfer}>Transfer NFT</button> : <button className="btn btn-sm btn-secondary mt-2" disabled="disabled">Transfer NFT</button>} */}

              <label className={`badge bg-${bgcolor}`}>{status}</label>
              <br />

              {/* <input type="text" placeholder="https://path/to/audio" className="mt-2" onChange={this.putTokenForSale} /> */}



              {!this.props.price && this.props.selectedAddress && this.props.requestbtn ? <button className="btn btn-success mt-2 btn-sm" data-id={this.state.nft.id} onClick={this.props.modalbtn}>{this.props.btntxt ? this.props.btntxt.public: 'Request NFT'} </button> : ''}

              {/* <button type='button' onClick={this.showAlert()}>Show Alert</button> */}

              {/* Main------ &nbsp; {this.props.selectedAddress && this.props.price && this.props.buybtn ? <button className="btn btn-warning mt-2 btn-sm" onClick={this.buyNft}>Buy NFT @ {this.props.price}</button> : <button className="btn btn-secondary mt-2 btn-sm" disabled>Buy NFT</button>} */}



              {/* {this.state.nft.private} */}
              {/* {this.props.buybtn ? "Hi" : "V"} */}

              &nbsp; <Link style={{ padding: '5px 20px' }} className="btn brand-btn btn-sm mt-2 btn-sm" to={`/NFTDetails/${this.state.nft.id}`}> {this.props.btntxt ? this.props.btntxt.detail: 'View Detail'} </Link>

              {!this.props.transferbtn && this.props.selectedAddress && this.state.nft.processing_state === "failed" && <p className='mb-0 mt-1'>Audio Processing Failed. Try Again</p>}

              {!this.props.price && this.props.selectedAddress && this.props.requestbtn ? <div><br/><Link to={`/AddAudio/${this.state.nft.id}`} className="btn brand-btn px-5"> {this.props.btntxt ? this.props.btntxt.audio: 'Upload Audio'}  </Link> </div>: ''}

            </div>

            {/* <div className="text-right" style={{ color: '#e5b700', textAlign: 'right' }}>{this.state.nft.id}</div> */}
          </div>
        </div>
      </div>
    )
  }
}