import React, { Component } from 'react';
import WOW from 'wowjs';
import { Loading } from '../components/Loading.js';

export default class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      aboutdata: [],
      loading: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    
    let myabout = await this.getAbout();

    this.setState({ aboutdata: myabout.data.data, loading: false });

    new WOW.WOW({
      live: false
    }).init();
  }

  async getAbout() {
    const response = await fetch(`${process.env.REACT_APP_HOST_URL}about/1`)
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }

  render() {

    const aboutitems = this.state.aboutdata;

    return (
      <div>
        {this.state.loading ? <Loading /> : ''}
        <main className="main-site-wrap">


          {/* <!-- Start of aboutus wrap --> */}
          <section className="aboutus-wrap py-50">
            <div className="container">
              <div className="row align-items-center justify-content-center">

              </div>
            </div>
          </section>
          {/* <!-- End of aboutus wrap --> */}

          {/* <!-- Start of Section wrap --> */}
          <section className="section-wrap py-50 pt-0">


            <div className="static-txt-wrap">
              <div className="container position-relative">
                <div className="static-txt-contentwrap">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-heading wow animate__fadeInUp pb-4">
                        <h3 className="title">{aboutitems.text}</h3>
                      </div>
                      <div className="content-paragraph mt-3 wow animate__fadeInUp">
                        <h3 class="title font-w-normal mb-4">
                        <div className='mbclass'><div dangerouslySetInnerHTML={{ __html: aboutitems.content }}></div></div>
                        </h3>
                      </div>
                    </div>

                    {/* <div className="col-12">
                      <div className="section-heading wow animate__fadeInUp pb-4">
                        <h3 className="title"> How does it work? </h3>
                      </div>
                      <div className="content-paragraph mt-3 wow animate__fadeInUp">
                        <p>New Money Sculptural Cryptocurrency is the artwork/project of Jason Bulluck. This project
                          represents a discursive gesture towards a ‘brief utopia’ or space of cooperative exploration and
                          exchange of liberatory ideas and resources in the face of the widespread exploitation of the
                          great range of our collective labors. This is especially true in the context of a white supremacist,
                          anti-black and therefore misogynist, anti-Indigenous, transphobic, homophobic, and ablelist,
                          global capitalist hegemony.</p>
                        <p>
                          The extra social, political, emotional, psychological, somatic, intellectual, sexual and sundry
                          other work that people who hold one or more of these identities must perform is flattened by the
                          exchange of highly abstracted money forms (cash, etc.) and we can wonder what might happen,
                          in terms of our habits of consumption, politics, etc. if we knew more about how the sausage is
                          mage, and more specifically, if we unflatten the labor hidden in money by making it known that
                          so many of us work, much to hard, in unseen and largely unheralded ways.</p><p>
                          Towards this end of unflattening, NM$. Throwing hidden labor ‘into relief’ is a sculptural
                          intervention in the art historical vein of much African and Indigenous art, which can make plain
                          the needs and struggles of community through symbolic form and ritual community discourse.
                          These sculptural themes have been taken up in times sense by modern and contemporary
                          artists whose work also seeks to intervene in the socio-political context towards liberation and
                          equality for all.</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

          </section>



        </main>


        <script src="assets/js/jquery.min.js"></script>
        <script src="assets/js/bootstrap.bundle.min.js"></script>
        <script src="assets/js/WOW.js"></script>
        <script src="assets/js/custom.js"></script>
      </div>
    )
  }
}
