import React, { Component } from 'react';
import { Loading } from '../components/Loading.js';

export default class FAQ extends Component {

  constructor(props) {
    super(props);
    this.state = {
      faqdata: [],
      loading: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    let myfaq = await this.getFaq();

    this.setState({ faqdata: myfaq.data.data, loading: false });

  }

  async getFaq() {
    const response = await fetch(`${process.env.REACT_APP_HOST_URL}faq`)
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }

  render() {

    const faqitems = this.state.faqdata;
    // console.log(faqitems.data);
    return (
      <div>
        {this.state.loading ? <Loading /> : ''}
        <main className="main-site-wrap">


          {/* <!-- Start of Related wrap --> */}
          <section className="faq-wrap py-50">
            <div className="container">
              <div className="accordion accordion-block" id="FAQAccordion">
                {faqitems && faqitems.map((faqs, i) => {
                  return (
                    <div className="accordion-box" key={i}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={`FAQ-${i}`}>
                          <button className={i == 0 ? 'accordion-button' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={`#FAQD-${i}`} aria-expanded="true" aria-controls="FAQOne">
                            {faqs.question}
                          </button>
                        </h2>
                        <div id={`FAQD-${i}`} className={i == 0 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} aria-labelledby={`FAQ-${i}`} data-bs-parent="#FAQAccordion">
                          <div className="accordion-body">
                            <div className='mbclass'><div dangerouslySetInnerHTML={{ __html: faqs.answer }}></div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                {/* <div className="accordion-box">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="FAQ-headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQOne" aria-expanded="true" aria-controls="FAQOne">
                        WHAT IS NEW MONEY SCULPTURAL NFT-CRYPTOCURRENCY (NM$)?
                      </button>
                    </h2>
                    <div id="FAQOne" className="accordion-collapse collapse show" aria-labelledby="FAQ-headingOne" data-bs-parent="#FAQAccordion">
                      <div className="accordion-body">
                        <p> New Money Sculptural NFT-Cryptocurrency are NFTs (built on the Ethereum blockchain) that
                          behave like cryptocurrency in the context of the New Money Private Exchange. </p>
                        <p> The New Money Private Exchange is a space where NM$ NFTs can be bought and sold.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-box">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="FAQ-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQTwo" aria-expanded="false" aria-controls="FAQTwo">
                        HOW IS NM$ SCULPTURAL?

                      </button>
                    </h2>
                    <div id="FAQTwo" className="accordion-collapse collapse" aria-labelledby="FAQ-headingTwo" data-bs-parent="#FAQAccordion">
                      <div className="accordion-body">
                        <p>
                          In order to participate in the NM$ Private Exchange, you have already had to spend some cash,
                          whether for transportation, attire that feels appropriate, an internet-enabled device and service,
                          etc. Cash, while it represents a kind of value to us all, cannot tell the stories of how it's been
                          earned beyond the obvious ideas of what it means to be employed in different jobs, and as a
                          result, the things we do to make</p>
                        <p>
                          Each NM$ NFT-Crypto-coin image is encoded with the recordings--made by each owner--of the
                          hidden labor that made ownership of NM$ possible. This record throws the labor we are never
                          paid for, but which makes life possible, into relief. This unflattening of our hidden labor is what
                          makes NM$ sculptural.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-box">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="FAQ-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQThree" aria-expanded="false" aria-controls="FAQThree">
                        HOW MUCH DOES EACH NM$ CRYTPO-COIN COST?

                      </button>
                    </h2>
                    <div id="FAQThree" className="accordion-collapse collapse" aria-labelledby="FAQ-headingThree" data-bs-parent="#FAQAccordion">
                      <div className="accordion-body">
                        <p>
                          NM$ crypto-coin costs are limited to a small fee per transaction (called a 'gas' fee, which is used
                          to cover the transaction costs for making trades on the Ethereum network.). In order to
                          complete a purchase of NM$, your participation is also required by listening to and recording
                          10-45 second histories of some aspect of the hidden labor expended in order to make a
                          purchase of a New Money NFT-coin. This might be a short description of social labor
                          (organizing a group of friends to support learning about an idea like liberatory cryptocurrency),
                          emotional labor (e.g. resilience in the face of dehumanizing forces like traffic) political labor (like
                          resistance in the face of marginalizing forces like micro-aggressions), etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-box">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="FAQ-headingfour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQfour" aria-expanded="false" aria-controls="FAQfour">
                        HOW DO I PARTICIPATE IN THE NM$ PRIVATE EXCHANGE?
                      </button>
                    </h2>
                    <div id="FAQfour" className="accordion-collapse collapse" aria-labelledby="FAQ-headingfour" data-bs-parent="#FAQAccordion">
                      <div className="accordion-body">
                        <p>
                          If you would simply like to browse the NM$ NFT-coins, you simply need a browser and you can
                          navigate to the NM$ private exchange (link). In order to trade NM$ NFT-coins you will need to
                          connect a crypto wallet. Currently the following crypto wallets can be used in the NM$ Private
                          Exchange (links to: Metamask, and Coinbase).
                        </p><p>Once you have connected your Metamask or Coinbase wallet to your New Money account (link
                          needed here) you will be able to browse, sell, and make requests to buy specific NM$
                          NFT-coins from the NM$ Private Exchange.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-box">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="FAQ-headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQFive" aria-expanded="false" aria-controls="FAQFive">
                        WHAT IS A CRYPTO WALLET?
                      </button>
                    </h2>
                    <div id="FAQFive" className="accordion-collapse collapse" aria-labelledby="FAQ-headingFive" data-bs-parent="#FAQAccordion">
                      <div className="accordion-body">
                        <p>
                          A crypto wallet is the place that stores the private keys (your encrypted passwords) that allow
                          you to trade cryptocurrency and NFTs on the blockchain.

                        </p><p>New Money NFT-cryptocurrency currently allows you to use Metamask and Coinbase
                          wallets(links)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-box">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="FAQ-headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQSix" aria-expanded="false" aria-controls="FAQSix">
                        IS IT POSSIBLE TO OWN AN NM$ NFT OUTSIDE OF THE PRIVATE EXCHANGE?                      </button>
                    </h2>
                    <div id="FAQSix" className="accordion-collapse collapse" aria-labelledby="FAQ-headingSix" data-bs-parent="#FAQAccordion">
                      <div className="accordion-body">
                        <p>
                          Yes, you can remove an NM$ NFT-coin from the Private Exchange for your personal collection,
                          or to trade on the OpenSea NFT platform(link). In order to purchase your NM$ NFT-coin from
                          the NM$ Private Exchange, simply click the ‘Transfer to OpenSea’ button on the NM$ details
                          page and a request to purchase will be sent to the NM$ Private Exchange owner/artist. At this
                          point you will be able to negotiate a sale price for your NM$ NFT, remove it from the NM$
                          Private Exchange to store in your collection or trade on OpenSea(link).
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          {/* <!-- Start of Related wrap --> */}


        </main>


        {/* <!-- Modal --> */}
        <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-black" id="exampleModalLabel"> Related Video</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="text-center">
                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/Gjo1hlIey9g" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-black" id="exampleModalLabel"> Related Video</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="text-center">
                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/Gjo1hlIey9g" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <script src="assets/js/jquery.min.js"></script>
        <script src="assets/js/bootstrap.bundle.min.js"></script>
        <script src="assets/js/WOW.js"></script>
        <script src="assets/js/custom.js"></script>
      </div>
    )
  }
}
