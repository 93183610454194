import React, { Component } from 'react';

export default class NotAuthorised extends Component {

  render() {
    return (
      <div>
        <main className="main-site-wrap">


          {/* <!-- Start of aboutus wrap --> */}
          <section className="aboutus-wrap py-50">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                
              </div>
            </div>
          </section>
          {/* <!-- End of aboutus wrap --> */}

          {/* <!-- Start of Section wrap --> */}
          <section className="section-wrap py-50 pt-0">

            {/* <!-- Start of Static img wrap --> */}
            <div className="static-txt-wrap">
              <div className="container position-relative">
                <div className="static-txt-contentwrap">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-heading wow animate__fadeInUp pb-4 text-center">
                        <h3 className="title"> Please login to access this page </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </main>
      </div>
    )
  }
}
