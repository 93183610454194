import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.js';
import '../assets/css/animation.css';
import ScrollToTop from "./ScrollToTop";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../pages/Home";
// import AllNft from "../components/AllNfts";
import NftExchange from "../components/AllNfts";
import MyNfts from "../components/MyNfts";

import About from '../pages/About';
// import Video from '../pages/Video';
import FAQ from '../pages/FAQ';
import AddAudio from '../pages/Add-Audio';

// import NFTDetails from '../pages/NFT-Details';
import NFTDetailsNew from "./NFT-Details-New";

// // import PrivacyPolicy from '../PrivacyPolicy';
// import Profile from '../pages/Profile';
// import RequestedNFT from '../pages/RequestedNFT';
import TermsOfService from '../pages/TermsOfService';

// We'll use ethers to interact with the Ethereum network and our contract
import { ethers } from "ethers";

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import NewMoneyArtifact from "../contracts/NewMoney.json";
import contractAddress from "../contracts/contract-address.json";
// import NotFound from "../pages/NotFound";
import NotAuthorised from "../pages/NotAuthorised";
import Swal from 'sweetalert2';

// All the logic of this dapp is contained in the Dapp component.
// These other components are just presentational ones: they don't have any
// logic. They just render HTML.
// import { NoWalletDetected } from "./NoWalletDetected";
// import { ConnectWallet } from "./ConnectWallet";
// import MyNfts from "./MyNfts";
// import AllNfts from "./AllNfts";
// import { Loading } from "./Loading";

// This is the Hardhat Network id, you might change it in the hardhat.config.js.
// If you are using MetaMask, be sure to change the Network id to 1337.
// Here's a list of network ids https://docs.metamask.io/guide/ethereum-provider.html#properties
// to use when deploying to other networks.
const HARDHAT_NETWORK_ID = '4';

// This is an error code that indicates that the user canceled a transaction
// const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

// This component is in charge of doing these things:
//   1. It connects to the user's wallet
//   2. Initializes ethers and the Token contract
//   3. Polls the user balance to keep it updated.
//   4. Transfers tokens by sending transactions
//   5. Renders the whole application
//
// Note that (3) and (4) are specific of this sample application, but they show
// you how to keep your Dapp and contract's state in sync,  and how to send a
// transaction.
export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    // We store multiple things in Dapp's state.
    // You don't need to follow this pattern, but it's an useful example.
    this.initialState = {
      // The info of the token (i.e. It's Name and symbol)
      tokenData: undefined,
      // The user's address and balance
      selectedAddress: undefined,
      balance: undefined,
      // The ID about transactions being sent, and any possible error with them
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      newMoney: undefined,
      btntrans: true,
      btnbuy: true,
      mprovider: undefined
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    this._checkIfWalletIsConnected();
  }



  render() {
    // window.localStorage.setItem('fprovider', '');
    // window.ethereum.on('accountsChanged', function (accounts) {
    //   window.localStorage.setItem('fprovider', '');
    //   window.location.reload();
    //   return () => window.ethereum.removeListener('accountsChanged', accounts);
    // });

    return (


      <Router>
        <ScrollToTop />

        {/* <ConnectWallet
          connectWallet={() => this._connectWallet()}
          networkError={this.state.networkError}
          dismiss={() => this._dismissNetworkError()}
        /> */}

        <Header userstate={this.state} connectwall={this._connectWallet} />

        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/AllNft" element={<AllNft btndisabled={false} selectedAddress={this.state.selectedAddress} contract={this.state.newMoney} />} /> */}

          {(this.state.newMoney || !this.state.selectedAddress) &&
            <Route exact path="/Marketplace" element={<NftExchange buybtn={true} selectedAddress={this.state.selectedAddress} contract={this.state.newMoney} />} />
          }
          {/* {(this.state.newMoney || !this.state.selectedAddress) &&
            <Route exact path="/Marketplace" element={<NftExchange buybtn={true} selectedAddress={this.state.selectedAddress} contract={this.state.newMoney} />} />
          } */}
          <Route exact path='/About' element={<About />} />

          {/* <Route exact path='/Video' element={<Video />} /> */}

          <Route exact path='/FAQ' element={<FAQ />} />

          <Route exact path='/AddAudio/:id' element={this.state.newMoney && this.state.selectedAddress ? <AddAudio selectedAddress={this.state.selectedAddress} contract={this.state.newMoney} /> : <NotAuthorised />} />


          <Route exact path='/MyNfts' element={this.state.newMoney ? <MyNfts buybtn={this.state.btnbuy} selectedAddress={this.state.selectedAddress} contract={this.state.newMoney} /> : <NotAuthorised />} />

          {(this.state.newMoney || !this.state.selectedAddress) &&
            <Route exact path='/NFTDetails/:id' buybtn={true}  element={<NFTDetailsNew selectedAddress={this.state.selectedAddress} contract={this.state.newMoney} />} />
          }
          {/* <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy />} /> */}
          {/* <Route exact path='/Profile' element={<Profile />} /> */}
          {/* <Route exact path='/RequestedNFT' element={<RequestedNFT />} /> */}
          <Route exact path='/TermsOfService' element={<TermsOfService />} />
          {/* <Route path="/404" element={<NotFound />} /> */}
          {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
        </Routes>

        <Footer />
      </Router>
    );
  }





  async _checkIfWalletIsConnected() {
    if (window.ethereum !== undefined) {

      let provider =  window.ethereum;


      if (window.ethereum.providers?.length) {
        await window.ethereum.providers.forEach(async (p) => {
            if (p.isCoinbaseWallet && window.localStorage.getItem('fprovider') == 'coinbase') {
              provider = p;
            } else if (p.isMetaMask && window.localStorage.getItem('fprovider') == 'meta') {
              provider = p;
            }
        })
      }

      // console.log(provider.isCoinbaseWallet)
      
      const [selectedAddress] = await provider.request({ method: 'eth_accounts', params: [], });

      if (selectedAddress) {
        this.state.btntrans = false;
        this._initialize(selectedAddress);
      } else {
        console.log("No authorized account found");
      }
    }
  }

  async _connectWallet(event) {
    const mval = event.currentTarget.value;
    // This method is run when the user clicks the Connect. It connects the
    // dapp to the user's wallet, and initializes it.
    // To connect to the user's wallet, we have to run this method.
    // It returns a promise that will resolve to the user's address.
    if (window.ethereum !== undefined) {

      try {



        let provider = window.ethereum;

        if (window.ethereum.providers?.length) {
          await window.ethereum.providers.forEach(async (p) => {

            if (p.isCoinbaseWallet && mval == "coinbase") {
              provider = p;
              window.localStorage.setItem('fprovider', 'coinbase');
            }
            else if (p.isMetaMask && mval == "meta") {
              provider = p;
              window.localStorage.setItem('fprovider', 'meta');
            }
          })
        }

        if (provider.isMetaMask && mval == 'coinbase') {

          Swal.fire({ title: 'Information', text: 'Install Coinbase Extentsion', icon: 'warning', confirmButtonText: 'OK', timer: 2500 }).then((result) => {

          });
          return false;
        }

        if (provider.isCoinbaseWallet && mval == 'meta') {

          Swal.fire({ title: 'Information', text: 'Install Metamask Extentsion', icon: 'warning', confirmButtonText: 'OK', timer: 2500 }).then((result) => {

          });
          return false;
        }

        // console.log(provider);

        const [selectedAddress] = await provider.request({ method: 'eth_requestAccounts', params: [], });

        if (!this._checkNetwork()) {
          alert("1");

        } else {
          alert("2")
        }

        this._initialize(selectedAddress);
      } catch (error) {
        // console.log(error)
        if (error.code === 4001) {
          // User rejected request
        }
        window.location.reload();
      }


      // Once we have the address, we can initialize the application.

      // First we check the network


      // We reinitialize it whenever the user changes their account.
      window.ethereum.on("accountsChanged", ([newAddress]) => {
        // `accountsChanged` event can be triggered with an undefined newAddress.
        // This happens when the user removes the Dapp from the "Connected
        // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
        // To avoid errors, we reset the dapp state 
        // alert("1")

        window.localStorage.setItem('fprovider', '');
        window.location.reload();

        if (newAddress === undefined) {

          return this._resetState();
        }

        this._initialize(newAddress);

      });




      // We reset the dapp state if the network is changed
      window.ethereum.on("chainChanged", ([networkId]) => {
        // console.log("HI")

        this._resetState();
      });
    } else {
      alert("Please install metamask or coinbase extension")
    }
  }

  async _initialize(userAddress) {
    console.log(userAddress);
    // This method initializes the dapp
    // console.log("HI")
    // We first store the user's address in the component's state
    this.setState({
      selectedAddress: userAddress,
    });

    // Then, we initialize ethers, fetch the token's data, and start polling
    // for the user's balance.
    // console.log("HI")
    // Fetching the token data and the user's balance are specific to this
    // sample project, but you can reuse the same initialization pattern.
    await this._initializeEthers();
  }

  async _initializeEthers() {
    // We first initialize ethers by creating a provider using window.ethereum

    let provider = window.ethereum;
    console.log(provider);
    if (window.ethereum.providers?.length) {
      await window.ethereum.providers.forEach(async (p) => {
        console.log(p)
        if (p.isCoinbaseWallet && window.localStorage.getItem('fprovider') == "coinbase") {
          provider = p;
        } else if (p.isMetaMask && window.localStorage.getItem('fprovider') == "meta") {
          provider = p;
        }

      })
    }
    // console.log(provider.isCoinbaseWallet)
    this._provider = new ethers.providers.Web3Provider(provider);
    const network = await this._provider.getNetwork();

    


    // Then, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.

    this._newMoney = new ethers.Contract(
      contractAddress.NewMoney,
      NewMoneyArtifact.abi,
      this._provider.getSigner(0)
    );
    // console.log(this._newMoney);
    this.setState({ newMoney: this._newMoney });

  }

  // This method just clears part of the state.
  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }

    return error.message;
  }

  // This method resets the state
  _resetState() {
    this.setState(this.initialState);
  }

  // This method checks if Metamask selected network is Localhost:8545 
  _checkNetwork() {
    if (window.ethereum.networkVersion === HARDHAT_NETWORK_ID) {
      return true;
    }

    this.setState({
      networkError: 'Please connect Metamask to the right network'
    });

    return false;
  }


}
