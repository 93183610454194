import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import Swal from 'sweetalert2';
import API from '../api.js';

export default class ModalForm extends Component {

    constructor(props) {

        super(props);

        this.api = new API(this.props.contract);

        this.state = { price: '', emailid: '', modtext: '', modalshow: props.isOpen, submitDisabled: false }

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        // this.handleChangePrice = this.handleChangePrice.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
        let popuptext = await this.api.getBuyPopup();
        if(popuptext.data.data)
        this.setState({modtext:popuptext.data.data})
    }

    handleChangePrice(event) {
        this.setState({ price: event.target.value });
    }
    handleChangeEmail(event) {
        this.setState({ emailid: event.target.value });
    }

    showAlert(mtitle, message, type, btntext, poption) {
        Swal.fire({ title: mtitle, text: message, icon: type, confirmButtonText: btntext, timer: 3500 }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            if (poption == "reload") {
              window.location.reload();
            }
          } else {
            if (poption == "reload") {
              window.location.reload();
            }
          }
        })
      }


    handleSubmit(event) {
        this.setState({ submitDisabled: true });
        event.preventDefault();
        if (this.props.userid) {
            const form = {
                userid: this.props.userid,
                // price: this.state.price,
                emailid: this.state.emailid,
                token: this.props.tokens
            }
            let uri =`${process.env.REACT_APP_HOST_URL}requestprice/`;
            axios.post(uri, form).then((response) => {

                this.setState({ submitDisabled: "" });
                if (response.data.data.status) {
                    let message = this.state.modtext.message ?  this.state.modtext.message : response.data.data.message;
                    this.showAlert("Message", message, 'success', 'OK' , '');
                    // alert(response.data.data.message)
                    this.props.closeModal();
                    this.setState({ price: ''});
                    this.setState({ emailid: ''});
                } else {
                    this.showAlert("Message", response.data.data.message, 'warning', 'OK' , '');
                }
                
            }).catch((error) => {
                this.showAlert("Message", "There is some error please try later", 'error', 'OK' , '');
                // alert("There is some error please try later");
                this.setState({ submitDisabled: "" });
            });
        } else {
            this.setState({submitDisabled: ""});
            alert("Please login");
        }
    }

    render() {
        return (
            <Modal show={this.props.isOpen} onHide={this.props.closeModal}>
                <form method="post" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-dark'>{this.state.modtext ? this.state.modtext.title : 'NFT Request' }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label className='text-dark'>{this.state.modtext ? this.state.modtext.descp : 'Share your Email to Jason to buy NFT for private exhange' }</label>
                                <input required type="email" value={this.state.emailid} onChange={this.handleChangeEmail} className="form-control" />
                            </div>
                            {/* <div className="form-group col-md-12">
                                <label className='text-dark'>Price:</label>
                                <input required type="number" value={this.state.price} onChange={this.handleChangePrice} className="form-control" />
                            </div> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" value="Submit" disabled={this.state.submitDisabled} color="primary" className="btn btn-primary" />
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
