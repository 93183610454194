// dotenv
require('dotenv').config();

// const apiKey = process.env.REACT_APP_API_KEY;

class API {
  constructor(contract) {
    this.contract = contract;
    // console.log("HI");
    // console.log(contract);
    this.nfts = [];
    this.nftsAreFetched = false;
    this.localUrl = "https://newmoneyart.com/";

  }

  async componentDidMount() {
    // this.contract = contract;
  }
  async getNfts() {
    if (this.nftsAreFetched === false) {
      let nfts = await fetchNfts(this.localUrl);
      console.log(this.contract);
      if (this.contract) {
        const transactions = await Promise.all(
          nfts.map(async txHash => {
            // console.log(txHash.id)
            // 0x57A2f5B4Ba8f6414ac134F687861692374F519b1
            const chkresult = await this.contract.isBoughtOffPrivateExchange(txHash.id,).then(function (token) { return token })
            if (chkresult) {
              txHash["private"] = false;
            } else {
              txHash["private"] = true;
            }

            return txHash
          })
        );
        this.nfts = transactions;
      } else {
        this.nfts = nfts;

      }

      this.nftsAreFetched = true;
    }

    // wrap in a promise so we can use async/await
    return new Promise((resolve, reject) => {
      resolve(this.nfts);
    });
  }

  async getpriceNfts() {

    let pricenfts = await fetch(`${this.localUrl}pricetoken/`)
    return await pricenfts.json();
  }

  async getpriceNft(id) {

    let pricenfts = await fetch(`${this.localUrl}pricetoken/${id}`)
    return await pricenfts.json();
  }

  async getAudioRequests() {

    const params = new URLSearchParams();
    params.append('type', 'Processing');

    let audionfts = await fetch(`${this.localUrl}audiorequest`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: params,
    })
    return await audionfts.json();
  }

  async getAudioRequestsApproved(id = null) {

    const params = new URLSearchParams();
    params.append('type', 'Approved');
    params.append('token', id);

    let audionfts = await fetch(`${this.localUrl}audiorequest`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: params,
    })
    return await audionfts.json();
  }



  async getNft(id) {
    const response = await fetch(`${this.localUrl}tokenlist/${id}`)
    // return await response.json();
    let mynft = await new Promise((resolve, reject) => {
      resolve(response.json());
    });
    if (this.contract) {

      const chkresult = await this.contract.isBoughtOffPrivateExchange(mynft.id,).then(function (token) { return token });
      console.log("conract", chkresult)
      if (chkresult) {
        mynft["private"] = false;
      } else {
        mynft["private"] = true;
      }
    }

    // console.log(mynft);
    return mynft;
  }

  // This method should never be on the front end
  // Since it exposes the API key, it should be on the backend.
  async putTokenForSale(id, audioSnippetUrl) {
    // fake uploading an audio snippet
    // const ipfsUrl = await this.uploadAudioSnippet(audioSnippet);

    return await fetch(`${this.baseUrl}tokensale/`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'audio_snippet_url': audioSnippetUrl, 'token_id': id }),
      // body: JSON.stringify({
      //   audio_snippet_url: audioSnippetUrl,
      // }),
      // headers: {
      //   "x-api-key": apiKey,
      // }
    })
  }

  // This method should never be on the front end
  // Since it exposes the API key, it should be on the backend.
  async fetchMintingSignature(from, to, tokenId) {
    // generate unique nonce
    const nonce = Math.floor(Math.random() * 100000);

    // const response = await fetch(`${this.baseUrl}signatures/transfers`, {
    //   method: "POST",
    //   body: JSON.stringify({ from, to, tokenId, nonce }),
    //   headers: {
    //     "x-api-key": apiKey,
    //   },
    // });
    const response = await fetch(`${this.localUrl}tokenmint/`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'from': from, 'to': to, 'tokenId': tokenId, 'nonce': nonce }),
    });
    const { signature } = await response.json();
    return { signature, nonce };
  }

  // This method should never be on the front end
  // Since it exposes the API key, it should be on the backend.
  async fetchBuyingSignature(owner, tokenId, price) {
    // generate unique nonce

    const response = await fetch(`${this.localUrl}tokenbuy/`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'owner': owner, 'tokenId': tokenId, 'price': price }),
    });
    // console.log(response);
    const { signature } = await response.json();
    return { signature };
  }

  async getOwnerOf(id) {
    console.log('getOwnerOf')
    console.log(id)
    let response = await this.contract.ownerOf(id)
    console.log('done')
    return response
  }

  async getTokensOfOwner(selectedAddress) {
    let tokenIds = await this.getTokenIDsOfOwner(selectedAddress);
    let nfts = await this.getNfts();
    // filter tokens by id
    // const nftsa = await this.chkTokenAvailability(nfts, tokenIds)


    // console.log("hi")
    // nftsa["key3"] = false;
    // console.log(transactions);
    // return transactions;
    return nfts.filter(nft => tokenIds.has(nft.id.toString()));
  }

  async chkTokenAvailability(nftc, tokenIds) {
    // console.log(nftc)
    const nft = await nftc.filter(async nft => {
      nft["private"] = await this.contract.isBoughtOffPrivateExchange(
        nft.id,
      );
      return await tokenIds.has(nft.id.toString())
    });
    // const response = 

    // console.log(response);
    return nft;
  }

  async getTokenIDsOfOwner(selectedAddress) {

    const sentLogs = await this.contract.queryFilter(
      this.contract.filters.Transfer(selectedAddress, null),
    );
    const receivedLogs = await this.contract.queryFilter(
      this.contract.filters.Transfer(null, selectedAddress),
    );

    const logs = sentLogs.concat(receivedLogs)
      .sort(
        (a, b) =>
          a.blockNumber - b.blockNumber ||
          a.transactionIndex - b.TransactionIndex,
      );

    const owned = new Set();

    for (const log of logs) {
      const { from, to, tokenId } = log.args;

      if (addressEqual(to, selectedAddress)) {
        owned.add(tokenId.toString());
      } else if (addressEqual(from, selectedAddress)) {
        owned.delete(tokenId.toString());
      }
    }

    return owned;
  };

  async getHome() {
    const response = await fetch(`${this.localUrl}banners/1`)
    // return await response.json();
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }

  async getButtons() {
    const response = await fetch(`${this.localUrl}buttons/1`)
    console.log("BTN", response)
    // return await response.json();
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }

  async getBuyPopup() {
    const response = await fetch(`${this.localUrl}buypop/1`)
    // return await response.json();
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }
}



async function fetchNfts(base) {
  let response = await fetch(`${base}tokenlist`)
  let nfts = await response.json();


  return nfts.tokens;
}

function addressEqual(a, b) {
  return a.toLowerCase() === b.toLowerCase();
}

export default API