import React, { Component } from 'react'
import WOW from 'wowjs'
import { Link } from 'react-router-dom'
import { Loading } from '../components/Loading.js';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homedata: [],
      bannerdata: [],
      loading: true,
    };
  }
  async componentDidMount() {
    // alert("1")
    // window.scrollTo(0, 0)
    let mybanner = await this.getBanner();
    let myhome = await this.getHome();
    // console.log();
    this.setState({ bannerdata: mybanner.data.data, homedata: myhome.data.data, loading: false });

    // console.log(this.state);
    // console.log()
    new WOW.WOW({
      live: false,
    }).init();
  }

  async getBanner() {
    const response = await fetch(`${process.env.REACT_APP_HOST_URL}banners/1`)
    // return await response.json();
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }

  async getHome() {
    const response = await fetch(`${process.env.REACT_APP_HOST_URL}homepage/1`)
    // return await response.json();
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }
  renderHTML(rawHTML) {
    React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })
  };

  render() {

    const banneritems = this.state.bannerdata;
    const homeitems = this.state.homedata;

    return (
      <div>
        {this.state.loading ? <Loading /> : ''}

        <main className="main-site-wrap">
          {/* <!-- Start of Banner wrap --> */}

          {/* <!-- End of Banner wrap -->

<!-- Start of Section wrap --> */}
          <section className="section-wrap homeslide">
            {/* <!-- Start of Static img wrap --> */}
            <div className="static-img-wrap">
              <div className="container position-relative">
                <div className="static-img-box wow animate__zoomIn">
                  <img className="img-fluid" alt='What is New Money' src={process.env.REACT_APP_HOST_URL + banneritems.image} />
                </div>
                <div className="static-img-contentwrap">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                      <div className="section-heading">
                        <h2 className="big-heading-two text-center text-uppercase wow animate__zoomIn animate__delay-1s">
                          <small style={{ fontSize: '30px' }}>{banneritems.text}</small>  <br /> {banneritems.descp}
                        </h2>
                      </div>
                      <div className="video-btn text-center wow animate__bounceIn animate__delay-2s pt-5">
                        <button style={{ margin: ' 0 auto' }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                          className="video-txt-btn btn d-flex p-0 btn justify-content-center align-items-center"
                        >
                          {' '}
                          <span> Watch Video </span>{' '}
                          <i className="ms-2 far fa-play-circle"></i>{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End of Static img wrap --> */}
          </section>
          {/* <!-- End of Section wrap --> */}


          {/* <section className="section-wrap py-100">
            <div className="static-txt-wrap">
              <div className="container">
                <div className="static-txt-contentwrap">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                      <div className="section-heading wow animate__zoomIn animate__delay-2s">
                        <h3 className="big-heading-three text-center">
                          The New Money Private Exchange is a space where NM$
                          NFTs can be bought and sold.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}


          {/* <section className="section-wrap py-100">
            <div className="static-img-wrap">
              <div className="container position-relative">
                <div className="static-img-box wow animate__zoomIn">
                  <img className="img-fluid" alt='New Money' src={require('../assets/images/nm$_logo_bg.png')} />
                </div>
                <div className="static-img-contentwrap">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                      <div className="section-heading wow animate__zoomIn animate__delay-2s">
                        <h3 className="big-heading-three text-center">
                          New Money Sculptural NFT-Cryptocurrency are NFTs
                          (built on the Ethereum blockchain) that behave like
                          cryptocurrency in the context of the New Money Private
                          Exchange.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* 
          <section className="section-wrap py-100">
            <div className="container block-two">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-6 left-content-block pe-lg-5">
                  <div className="left-box wow animate__fadeInLeft">
                    <h3 className="title font-w-normal">
                      The New Money Private Exchange is a space where NM$ NFTs
                      can be bought and sold.New Money Sculptural Cryptocurrency
                      is the artwork/project of Jason Bulluck. This project
                      represents a discursive gesture towards a ‘brief utopia’
                      or space of cooperative exploration and exchange of
                      liberatory ideas and resources in the face of the
                      widespread exploitation of the great range of our
                      collective labors. This is especially true in the context
                      of a white supremacist, anti-black and therefore
                      misogynist, anti-Indigenous, transphobic, homophobic, and
                      ablelist, global capitalist hegemony.
                    </h3>
                  </div>
                </div>

                <div className="col-12 col-lg-6 right-img-block py-lg-5">
                  <div className="banner-right-thmb py-5 wow animate__fadeInRight animate__delay-3s">
                    <img
                      className="img-fluid" alt='New Money' src={require('../assets/images/GoldNMS_Metal_innerbrand.png')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section> */}



          <section className="section-wrap py-100">
            <div className="container block-two">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-6 left-content-block pe-lg-5">
                  <div className="left-box wow animate__fadeInLeft">
                    <h3 className="title font-w-normal mb-4">
                      <div className='mbclass'> <div dangerouslySetInnerHTML={{ __html: homeitems.text }}></div></div>
                    </h3>
                  </div>
                </div>

                <div className="col-12 col-lg-6 right-img-block py-lg-5">
                  <div className="banner-right-thmb py-5 wow animate__fadeInRight animate__delay-3s">
                    <img
                      className="img-fluid" alt='New Money' src={process.env.REACT_APP_HOST_URL + homeitems.image}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* 
          <section className="section-wrap py-100">
            <div className="static-img-wrap">
              <div className="container position-relative">
                <div className="static-img-contentwrap">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section className="section-wrap py-100">
            <div className="static-img-wrap">
              <div className="container position-relative">
                <div className="static-img-box wow animate__fadeInRight">
                  <img
                    className="img-fluid" alt='How Does Money Work' src={require('../assets/images/nm$_logo_rightbg.png')}
                  />
                </div>
                <div className="static-img-contentwrap">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-8">
                      <div className="section-heading wow animate__fadeInLeft animate__delay-2s">
                        <h2 className="big-heading-one text-uppercase">
                          How Does New Money Work?
                        </h2>
                      </div>
                      <div className="video-btn wow animate__bounceInLeft animate__delay-4s pt-5">
                        <Link
                          to="/FAQ"
                          className="video-txt-btn d-flex align-items-center"
                        >
                          <span> Find the full New Money FAQ here</span>
                          <i className="ms-2 far fa-play-circle"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section className="section-wrap py-100">
            <div className="container block-two">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-7 left-img-block py-lg-5">
                  <div className="banner-left-thmb py-5 wow animate__fadeInLeft animate__slower">
                    <img
                      className="img-fluid" alt='New Money' src={require('../assets/images/GoldNMS_Metal_innerbrand.png')}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-5 right-content-block">
                  <div className="right-box wow animate__fadeInRight animate__delay-3s animate__slower">
                    <h3 className="title font-w-normal pb-3">
                      If you would simply like to browse the NM$ NFT-coins, you
                      simply need a browser and you can navigate to the NM$
                      private exchange (link). In order to trade NM$ NFT-coins
                      you will need to connect a crypto wallet. Currently the
                      following crypto wallets can be used in the NM$ Private
                      Exchange (links to: Metamask, and Coinbase).
                    </h3>
                    <h3 className="title font-w-normal pb-3">
                      Once you have connected your Metamask or Coinbase wallet
                      to your New Money account (link needed here) you will be
                      able to browse, sell, and make requests to buy specific
                      NM$ NFT-coins from the NM$ Private Exchange.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section className="section-wrap py-100">
            <div className="static-txt-wrap block-four-wrap">
              <div className="container">
                <div className="section-heading">
                  <h2 className="big-heading-two text-center pb-3 pb-lg-5 wow animate__fadeInDown">
              
                    Navigation
                  </h2>
                </div>
                <div className="row text-center align-items-center justify-content-center">
                  <div className="col-md-3 block-four position-relative wow animate__fadeInUp animate__delay-1s">
                    <div className="block-four-box">
                      <p> What is New Money Crypto </p>
                    </div>
                  </div>
                  <div className="col-md-3 block-four position-relative wow animate__fadeInUp animate__delay-5s">
                    <div className="block-four-box">
                      <p>
                       
                        How the New Money Sculptural Cryptocurrency unflattens
                        our hidden labors
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 block-four position-relative wow animate__fadeInUp animate__delay-4s">
                    <div className="block-four-box">
                      <p> How to participate </p>
                    </div>
                  </div>
                  <div className="col-md-3 block-four position-relative wow animate__fadeInUp animate__delay-2s">
                    <div className="block-four-box">
                      <p> How currencies flatten labor </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}


          {/* <section className="section-wrap py-100">
            <div className="container block-two">
              <div className="row align-items-end justify-content-center">
                <div className="col-12 col-lg-6 pt-lg-5 left-content-block">
                  <div className="left-box pt-lg-5 pb-3 pb-lg-0 wow animate__fadeInLeft">
                    <h3 className="big-heading-three"> Stay in the loop </h3>
                    <p>
                      Join our mailing list to stay in the loop with our newest
                      feature releases, NFT drops, and tips and tricks for
                      navigating New Money Cryptocurrency.
                    </p>
                  </div>
                </div>

                <div className="col-12 col-lg-6 right-img-block py-lg-5">
                  <div className="joining-form-box wow animate__fadeInRight">
                    <form action="">
                      <div className="form-group">
                        <input
                          type="text"
                          name="useremail-id"
                          id="useremail-id"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <button
                          type="submit"
                          className="subscribe-btn white-btn"
                          id="subscribe"
                        >
                          Subscribe
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

        </main>

        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-black" id="exampleModalLabel">
                  What Is New Money?
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="text-center">
                    <iframe
                      width="100%"
                      height="415"
                      src={`https://youtube.com/embed/${banneritems.videolink}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Home