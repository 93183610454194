import React, { Component } from 'react';
import API from '../api.js'
import Nft from './Nft.js'
import { Loading } from "./Loading";
import ModalForm from '../components/ModalForm.js';

export default class AllNfts extends Component {
  constructor(props) {
    super(props);

    // console.log("HHHHH", this.props.selectedAddress);

    this.contract = this.props.contract;
    this.selectedAddress = this.props.selectedAddress;
    this.api = new API(this.props.contract);

    console.log("HHHHH", this.props.contract);

    this.state = {
      nfts: [],
      fid: [],
      fpid: [],
      mpdata: [],
      audids:[],
      audapids:[],
      loading: true,
      tokenIds: '',
      buttonstxt:''
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = () => this.setState({ isOpen: false });

  }
  

  async openModal(event) {
    
    this.setState({ isOpen: true });

    await this.setState({ tokenid: event.target.getAttribute('data-id') }, () => {
      console.log(this.state.tokenid);
    });
  }

  async componentDidMount() {

    let mympdata = await this.getMarketPlace();

    let btndata = await this.api.getButtons();

    console.log("BDATA", mympdata);

    let nfts = await this.api.getNfts();
    let pricenfts = await this.api.getpriceNfts();
    let audiofts = await this.api.getAudioRequests();
    let audioftsapp = await this.api.getAudioRequestsApproved();

    let tokenId = ''

    if (this.props.selectedAddress) {
     tokenId = await this.api.getTokenIDsOfOwner(this.props.selectedAddress);
    }
    let followingIds = '';
    let followingPrice = ''; 
    let audioids = '';
    let audioappids = '';

    if (pricenfts.data && pricenfts.data.status) {
      followingIds = pricenfts.data.tokenlist.map(group => parseInt(group.tokenid));
      followingPrice = pricenfts.data.tokenlist.map(group => group.price);
    }
    
    if (audiofts.data && audiofts.data.status) {
      audioids = audiofts.data.data.map(group => parseInt(group.token_id));
      // console.log("audiocheck", audioids);     
    }

    if (audioftsapp.data && audioftsapp.data.status) {
      audioappids = audioftsapp.data.data.map(group => parseInt(group.token_id));
      // console.log("audiocheck", audioids);     
    }

    this.setState({ nfts: nfts, loading: false, fid: followingIds, fpid: followingPrice, audids:audioids, audapids:audioappids, tokenIds: tokenId, mpdata: mympdata.data.data, buttonstxt: btndata.data.data });
  }

  async getMarketPlace() {
    const response = await fetch(`${process.env.REACT_APP_HOST_URL}marketplace/1`)
    return new Promise((resolve, reject) => {
      resolve(response.json());
    });
  }

  createIpfsLink(ipfsHash) {
    return ipfsHash.replace("ipfs://", "https://ipfs.io/ipfs/");
  }

  simulateNftPurchase(nft, event) {
    // event.preventDefault();

    // console.log(event)
    console.log(nft)
    console.log("Simulating NFT purchase...");



  }

  render() {

    const mpitems = this.state.mpdata;
    const btn = this.state.buttonstxt;

    // console.log("MY", btn)

    const nftItems = this.state.nfts.map((nft, i) => {


      let chkprivate = nft.private;
      // console.log("Privae", chkprivate)
      let isForSale = nft.next_version !== null;
      nft = isForSale ? nft.next_version : nft;
      // console.log(this.createIpfsLink(nft.animation_url).duration);
      if (nft.processing_state == "failed") {
        nft.animation_url = nft.snippets.audio_snippet_1;
      } else {
        nft.animation_url = nft.animation_url;
      }
      let mprice = '';
      let aud = false;
      let claim = true;
      let owner = false;
      let tokprivate = false;
      let audexist = false;

      if (this.state.fid.includes(nft.id)) {
        mprice = this.state.fpid[this.state.fid.indexOf(nft.id)] + ' Eth';
      }

      if (this.state.tokenIds) {
        if (this.state.tokenIds.has(nft.id.toString())) {
          claim = false;
          owner = true;
        }
        
        if (!chkprivate) {
          tokprivate = true;
        }
      }else if(!this.props.selectedAddress){
        claim = false;
        owner = false;
      }
      if (this.state.audids.includes(nft.id)) {
        aud = true;
      }
      if (this.state.audapids.includes(nft.id)) {
        audexist = true;
      }
      return (
        <Nft key={i}
          isForSale={isForSale}
          contract={this.props.contract}
          selectedAddress={this.props.selectedAddress}
          transferbtn={claim}
          owned={owner}
          buybtn={false}
          modalbtn={this.openModal}
          price={''}
          nft={nft}
          requestbtn={false}
          audiostatus={aud}
          privatetok={tokprivate}
          audioapp={audexist}
          btntxt={btn}
        ></Nft>
      )
    })

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5 nftlist">
            {/* <h1>New Money NFTs ({this.state.nfts.length})</h1> */}
            <h1 className='mb-4'>{mpitems.text} </h1>
            <div className='mbclass'><div dangerouslySetInnerHTML={{ __html: mpitems.content }}></div></div>
          </div>
        </div>

        <hr />
        <div className="row justify-content-md-center position-relative" style={{ minHeight: '200px' }}>
          {this.state.loading ? <Loading /> : ''}
          {nftItems}
        </div>

        <ModalForm
          closeModal={this.closeModal}
          isOpen={this.state.isOpen}
          tokens={this.state.tokenid}
          loading={this.state.loading}
          userid={this.props.selectedAddress}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}