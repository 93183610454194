import React, { Component, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';
// import WOW from "wowjs";
// import { ethers } from "ethers";
import { ConnectWallet } from "./ConnectWallet";
// import Connect from "./connect";




export default class Header extends Component {

  componentDidUpdate() {
    // const [expanded, setExpanded] = useState("");
    this.selectedAddress = this.props.userstate.selectedAddress;
  }



  render() {

    const { navData } = this.props;
    const loc = window.location.pathname;
    let mylocs = '';
    if (loc.split('/').length == 3) {
      let myloc = loc.split('/');
      mylocs = myloc[1];
    }
    return (
      <div>
        {/* <p className="text-center mb-0">
          New Money NM$ NFT-Coin Drop and Exchange Launch Aug. 8!!
        </p> */}
        <header className="site-header">
          <div className="container">
            <Navbar className="p-3" collapseOnSelect fixed="top" expand="lg" variant="dark">
              <Navbar.Brand  as={Link} to="/"><img src={require('../assets/images/nms_circle_logo.png')} alt="logo" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarCollapse" />
              <Navbar.Collapse id="navbarCollapse">
                <Nav className="mx-lg-auto">
                  <Nav.Item>
                    <Nav.Link eventKey="1" as={NavLink} to="/">
                      Home
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2" as={NavLink} to="/About">
                      About
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3" as={NavLink} to="/Marketplace">
                      Marketplace
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4" as={NavLink} to="/FAQ">
                      FAQ
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Nav className="ms-lg-4 header-login">
                  <div className="d-flex align-items-lg-center mt-3 mt-lg-0">

                    {this.props.userstate.selectedAddress !== undefined && <Link to="/MyNfts">My NFTs</Link>}
                    {this.props.userstate.selectedAddress === undefined && <button data-bs-toggle="modal" data-bs-target="#SignUpPopup" className="btn white-border-btn">Log In</button>
                    }
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </header>
        <div
          className="modal fade login-popup-wrap"
          id="LogInPopup"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content signup-popup-content">
              <div className="modal-body">
                <div className="signup-popup-header text-center pt-5">
                  <div className="signup-popup-logo pb-5">
                    <img src={require('../assets/images/signup_logo.png')} alt="Logo" />
                  </div>
                  <h4>Connect With MetaMask </h4>
                  <p>Select the accounts to use on this site</p>
                </div>
                <div className="login-popup-selectbox p-4">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="LoginAC"
                      id="LoginAC1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="LoginAC1">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-3 text-center">
                          <img alt="Account" src={require('../assets/images/account_ic1.png')} />
                        </div>
                        <div className="col-9">
                          <h6> Account 1 (0x52231456754sd) </h6>
                          <p>2.9994667670 ETh</p>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="LoginAC"
                      id="LoginAC2"
                    />
                    <label className="form-check-label" htmlFor="LoginAC2">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-3 text-center">
                          <img alt="Account2" src={require('../assets/images/account_ic2.png')} />
                        </div>
                        <div className="col-9">
                          <h6> Account 2 (0x52231456754sd) </h6>
                          <p>2.9994667670 ETh</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
        <div
          className="modal fade signup-popup-wrap"
          id="SignUpPopup"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content signup-popup-content">
              <div className="modal-body">
                <div className="signup-popup-header text-center pt-5">
                  <div className="signup-popup-logo pb-5">
                    <img src={require('../assets/images/signup_logo.png')} alt="Logo" />
                  </div>
                  <h4> Login with Crypto/NFT wallet </h4>
                  <p>
                    Securely login with your Coinbase or Metamask crypto wallet to trade New Money on the NM$ Private Exchange here!
                  </p>
                </div>
                <div className="signup-popup-selectbox p-4">
                  <div className="signup-popup-radio">


                      {<ConnectWallet
                        connectWallet={event => this.props.connectwall(event)}
                        networkError={this.props.userstate.networkError}
                        dismiss={() => this._dismissNetworkError()}
                      />}

                   
                    {/* <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="SignUp_PopupSelect"
                        id="SignUp_PopupSelect2"
                      />
                      <label className="form-check-label" htmlFor="SignUp_PopupSelect2">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-3 text-center">
                            <img alt="Coinbase" src={require('../assets/images/coinbasewallet_dropdown_logo.png')} />
                          </div>
                          <div className="col-9">
                            <h5 className="m-0"> Coinbase Wallet </h5>
                          </div>
                        </div>
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
