import React, { Component } from 'react';
import API from '../api.js';
import Nft from './Nft.js'
import { Loading } from "./Loading";
import ModalForm from '../components/ModalForm.js';

export default class MyNfts extends Component {
  constructor(props) {
    super(props);
    this.contract = this.props.contract;
    this.selectedAddress = this.props.selectedAddress;
    this.api = new API(this.props.contract);

    this.state = {
      nfts: [],
      loading: true,
      fid: [],
      fpid: [],
      usrid: [],
      btnchecks: false
    };


    this.openModal = this.openModal.bind(this);
    // this.capitalizeProductsIds = this.capitalizeProductsIds.bind(this);
    this.closeModal = () => this.setState({ isOpen: false });
  }

  async componentDidMount() {
    // let nfts = await this.api.getNfts();
    this.setState({ loading: true });
    let nfts = await this.api.getTokensOfOwner(this.props.selectedAddress);

    let pricenfts = await this.api.getpriceNfts();
    let followingIds = '';
    let followingPrice = '';
    let userIds = '';
    if (pricenfts.data && pricenfts.data.status) {
      followingIds = pricenfts.data.tokenlist.map(group => parseInt(group.tokenid));
      userIds = pricenfts.data.tokenlist.map(group => group.userid);
      followingPrice = pricenfts.data.tokenlist.map(group => group.price);
    }

    this.setState({ nfts: nfts, loading: false, fid: followingIds, fpid: followingPrice, usrid: userIds });

    // this.setState({ nfts: nfts });
  }


  createIpfsLink(ipfsHash) {
    return "https://ipfs.io/ipfs/" + ipfsHash.replace("ipfs://", "");
  }

  async chkTokenAvailability(nftc) {
    // console.log(nftc)
    const response = await this.props.contract.isBoughtOffPrivateExchange(
      nftc,
    )
    console.log(response);
    return response;
  }

  capitalizeProductsIds = async (nft) => {
    // const products = await getProducts()
    // console.log(nft)

    const response = await this.props.contract.isBoughtOffPrivateExchange(
      nft,
    )

    this.setState({ btnchecks: true })
    // console.log(this.state.btnchecks)
    // this.setState({ btnchecks: true });
    return response;
  }

  async openModal(event) {
    // console.log(event.target.getAttribute('data-id'));
    this.setState({ isOpen: true });

    await this.setState({ tokenid: event.target.getAttribute('data-id') }, () => {
      console.log(this.state.tokenid);
    });
  }



  removeInsignificant(str) {
      str = str.replace(/^[\s0]+/, '');
      let ary = str.split('.');
      if (ary.length > 1) {
        ary[1] = ary[1].replace(/[\s0]+$/, '');
        if (ary[1].length === 0) {
          return ary[0];
        }
        else {
          return ary[0] + '.' + ary[1];
        }
      }
      return str;
    }


    render() {


      // console.log(capitalizeProductsIds());
      // console.log(this.state.btnchecks);

      const nftItems = this.state.nfts.map((nft, i) => {

        // console.log(this.chkTokenAvailability(nft.id))
        let buybtnchk = nft.private;
        let isForSale = nft.next_version !== null;
        nft = isForSale ? nft.next_version : nft;
        let mprice = '';
        if (nft.processing_state == "failed") {
          nft.animation_url = nft.snippets.audio_snippet_1;
        } else {
          nft.animation_url = nft.animation_url;
        }


        // console.log(nft.private)
        // const token = this.capitalizeProductsIds(nft.id);
        // if (token) {
        //   this.setState({ btnchecks: true });
        // } else {
        //   this.setState({ btnchecks: false })
        // }
        // const rxInsignificant = /^[\s0]+|(?<=\..*)[\s0.]+$|\.0+$|\.$/gm;
        if (this.state.fid.includes(nft.id) && this.state.usrid.includes(this.props.selectedAddress)) {
          mprice = (this.removeInsignificant(this.state.fpid[this.state.fid.indexOf(nft.id)])) + ' Eth';
        }

        // this.chkTokenAvailability(nft.id, this).then(function (token) {
        //   if (token) {
        //     mthis.setState({btnchecks: true})
        //   }
        //   console.log(token);
        // });

        //   useEffect(() => {
        //     this.chkTokenAvailability(nft.id).then(setCurrentPrice);
        // }, [info])

        return (
          <Nft key={i}
            isForSale={isForSale}
            contract={this.props.contract}
            selectedAddress={this.props.selectedAddress}
            btnstate={"1"}
            owned={true}
            nft={nft}
            buybtn={buybtnchk}
            requestbtn={true}
            modalbtn={this.openModal}
            price={mprice}
            transferbtn={false}
          ></Nft>
        )
      });

      return (
        <div className="container">


          <div>

            <div className="row mt-5">
              <div className="col-12">
                <h1>My NFTs ({this.state.nfts.length})</h1>
              </div>
            </div>

            <hr />
            <div className="row justify-content-md-center position-relative" style={{ minHeight: '200px' }}>
              {this.state.loading ? <Loading /> : ''}
              {nftItems}
            </div>
            <ModalForm
              closeModal={this.closeModal}
              isOpen={this.state.isOpen}
              tokens={this.state.tokenid}
              loading={this.state.loading}
              userid={this.props.selectedAddress}
              handleSubmit={this.handleSubmit}
            />
          </div>

        </div>
      );
    }

  }